import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo1.png';
const Navigation = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand"><img className="logo" src={logo} alt="Logo" /><span> OAK HEALTH HOSPITAL </span></div>
      <ul className="navbar-menu">
        <li className="navbar-item"><Link to={"/"}>Home</Link></li>
        <li className="navbar-item"><Link to={"/about"}>About Us</Link></li>
        <li className="navbar-item"><Link to={"/services"}>Services</Link></li>
        <li className="navbar-item"><Link to={"/contact"}>Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Navigation;
