import React from 'react';
import hero from '../hero.png';
import Contact from './Contact';
import About from './About';
import Services from './Services';
import Extra from './Extra';
import Review from './Review';


const Home = () => {
    const handleBookAppointment = () => {
        document.getElementById('booking-section').scrollIntoView({ behavior: "smooth" });
    };
    return (
        <div>
            {/* <header className="hero-section">
                <img src={hero} alt="Hospital" className="hero-image" />
                <div className="hero-content">
                    <h1>Welcome to Oak Health Hospital</h1>
                    <p>Oak Health Hospital is a dedicated healthcare provider offering exceptional medical care and wellness services.
                        Our modern facility, equipped with advanced technology, is staffed by a team of highly skilled and compassionate medical professionals committed to your well-being.</p>
                    <button onClick={handleBookAppointment} className="cta-button">Book an Appointment</button>
                </div>
            </header> */}

            <header className="hero-section">
                <img src={hero} alt="Hospital" className="hero-image" />
                <div className="hero-content">
                    <h1>Welcome to Oak Health Hospital</h1>
                    <h2 className="motto">"Health care with a difference..."</h2>
                    <p>
                        Oak Health Hospital is a dedicated healthcare provider offering exceptional medical care and wellness services.
                        Our modern facility, equipped with advanced technology, is staffed by a team of highly skilled and compassionate
                        medical professionals committed to your well-being.
                    </p>
                    <button onClick={handleBookAppointment} className="cta-button">Book an Appointment</button>
                </div>
            </header>


            <Services />
            <About />
            <Review />
            <Contact />
            {/* <Extra /> */}
        </div>
    );
}

export default Home;
