import React from 'react'

import { FaMedkit, FaHospital, FaHeart, FaHandsHelping } from 'react-icons/fa';
import meds from './meds.png';

const About = () => { 
  return (
    <div>
      <section id="about-us" className="about-us-section">
      <img src={meds} alt="Hospital" className="meds" />
                <div className="about-us-content">
                    <div className="about-us-text">
                        <h2>About Us</h2>
                        <p>We combine expertise, compassion, and cutting-edge technology to provide top-notch medical care. Our team of dedicated professionals is committed to improving the health and well-being of our community through comprehensive and personalized care.</p>
                    </div>
                    <div className="about-us-values">
                        <div className="value-card">
                            <FaHospital className="value-icon" />
                            <h3>Quality Care</h3>
                            <p>We provide exceptional care with a focus on improving patient outcomes and enhancing quality of life.</p>
                        </div>
                        <div className="value-card">
                            <FaHeart className="value-icon" />
                            <h3>Compassionate Service</h3>
                            <p>Our compassionate approach ensures that every patient feels valued, respected, and cared for.</p>
                        </div>
                        <div className="value-card">
                            <FaHandsHelping className="value-icon" />
                            <h3>Patient-Centered Approach</h3>
                            <p>We put patients at the center of their care, involving them in decisions to tailor treatments to their needs.</p>
                        </div>
                        <div className="value-card">
                            <FaMedkit className="value-icon" />
                            <h3>Innovative Solutions</h3>
                            <p>Using the latest medical technology and research, we offer innovative treatments and solutions.</p>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}

export default About
