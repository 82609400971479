let reviewsData = [

    {
        name: "Adeola Adebayo",
        rating: 5,
        text: "The doctors and nurses at this hospital are incredibly professional and caring. My surgery went smoothly, and the recovery process was well-managed.",
        date: "10/07/2023"
    },
    {
        name: "Emeka Okafor",
        rating: 3,
        text: "Good facilities and attentive staff. The waiting time could be improved, but overall, I'm satisfied with the care I received.",
        date: "12/010/2023"
    },
    {
        name: "Yemi Bakare",
        rating: 4,
        text: "I received excellent treatment in the oncology department. The doctors were compassionate and provided great care throughout my treatment.",
        date: "10/02/2024"
    },
    {
        name: "Fatima Bello",
        rating: 4,
        text: "Excellent maternity services! The nurses were supportive and helped me through the delivery with great care.",
        date: "02/11/2023"
    },
    {
        name: "Funmi Olatunji",
        rating: 5,
        text: "Amazing emergency care! The doctors and nurses were quick to respond and provided excellent treatment. I felt well taken care of.",
        date: "25/05/2024"
    },
    {
        name: "Ngozi Nwosu",
        rating: 4,
        text: "I appreciated the cleanliness and the modern equipment. The staff were friendly, the pharmacy was well stocked with medications I needed.",
        date: "20/02/2024"
    },
    {
        name: "Bukola Alabi",
        rating: 5,
        text: "Outstanding service! The doctors were very knowledgeable and the environment was very comfortable.",
        date: "15/10/2023"
    },
    {
        name: "Aisha Mohammed",
        rating: 4,
        text: "Great experience with the pediatric department. My child was well taken care of, and the doctors explained everything thoroughly.",
        date: "29/04/2024"
    },
];

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Convert DD/MM/YYYY to Date object
};

export const addReview = (review) => {
    reviewsData.push(review);
    reviewsData.sort((a, b) => parseDate(b.date) - parseDate(a.date)); // Sort by Date object
};

export { reviewsData };
