import React, { useState } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaCopy } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [messageStatus, setMessageStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.name || !formData.phone || !formData.message) {
            setMessageStatus({ type: 'error', text: 'Please fill out all fields.' });
            return;
        }

        setIsSubmitting(true);

        emailjs.send(
            'neWabsolute123',
            'template_h8pdhhn',
            formData,
            'L8TzD1r8BsLfSSi9M'
        )
        .then(response => {
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: ''
            });
            setMessageStatus({ type: 'success', text: 'Message sent successfully!' });
        })
        .catch(error => {
            console.error('Error:', error);
            setMessageStatus({ type: 'error', text: 'Failed to send message, please try again later.' });
        })
        .finally(() => {
            setIsSubmitting(false);
            setTimeout(() => setMessageStatus(null), 5000);
        });
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => setCopiedText(text))
            .catch(err => console.error('Could not copy text: ', err));
    };

    return (
        <div>
            <section id="contact-us" className="contact-us-section">
                <div className="contact-us-container">
                    <h2>Contact Us</h2>
                    <p>We're here to help you. Get in touch with us through any of the methods below.</p>
                    <div className="contact-details">
                        <div className="contact-card">
                            <FaCopy className="copy-icon" onClick={() => handleCopy('+2348183310100')} />
                            {copiedText === '+2348183310100' && <span className="copied-text">Copied!</span>}
                            <a href="tel:+2348183310100">
                                <FaPhoneAlt className="contact-icon" />
                                <h3>Phone</h3>
                                <h4>(234) 8183310100<br /> <br /></h4>
                                <p>Uninterrupted customer support, available around the clock.</p>
                            </a>
                        </div>
                        <div className="contact-card">
                            <FaCopy className="copy-icon" onClick={() => handleCopy('info@oakhealthhospital.com')} />
                            {copiedText === 'info@oakhealthhospital.com' && <span className="copied-text">Copied!</span>}
                            <a href="mailto:info@oakhealthhospital.com">
                                <FaEnvelope className="contact-icon" />
                                <h3>Email</h3>
                                <h4>info@oakhealthhospital.com</h4> <br />
                                <p>Leave us an email and we will respond as quickly as possible</p>
                            </a>
                        </div>
                        <div className="contact-card">
                            <FaCopy className="copy-icon" onClick={() => handleCopy('10 Irewunmi street, off ifesowapo street, Igbogbo road, Ikorodu, Lagos')} />
                            {copiedText === '10 Irewunmi street, off ifesowapo street, Igbogbo road, Ikorodu, Lagos' && <span className="copied-text">Copied!</span>}
                            <a href="https://www.google.com/maps/search/?api=1&query=10+Irewunmi+street,+off+ifesowapo+street,+Igbogbo+road,+Ikorodu,+Lagos" target="_blank" rel="noopener noreferrer">
                                <FaMapMarkerAlt className="contact-icon" />
                                <h3>Address</h3>
                                <p>10 Irewunmi street, off ifesowapo street, Igbogbo road, Ikorodu, Lagos</p>
                            </a>
                        </div>
                    </div>
                    <br />
                    
                    <hr />
                    <br /><br /><br />
                    <h1 id='booking-section'>Book an Appointment</h1>
                    <p>Fill in the form below to schedule your appointment.</p>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Your Phone Number"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            name="message"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Sending...' : 'Send Message'}
                        </button>
                    </form>
                    {messageStatus && (
                        <div className={`message ${messageStatus.type}`}>
                            {messageStatus.text}
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
}

export default Contact;
