import React, { useState } from 'react';
import { FaQuoteLeft, FaQuoteRight, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { reviewsData, addReview } from './myreview';

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Convert DD/MM/YYYY to Date object
};

const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' });
};

const getStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
            stars.push(<FaStar key={i} />);
        } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
            stars.push(<FaStarHalfAlt key={i} />);
        } else {
            stars.push(<FaStar key={i} style={{ opacity: 0.3 }} />);
        }
    }
    return stars;
};

const Reviews = () => {
    const sortedReviews = reviewsData.sort((a, b) => parseDate(b.date) - parseDate(a.date));
    const [reviews, setReviews] = useState(sortedReviews.slice(0, 8));
    const [newReview, setNewReview] = useState({
        name: '',
        rating: 5,
        text: '',
        date: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewReview({ ...newReview, [name]: value });
    };

    const handleAddReview = () => {
        if (newReview.name && newReview.text) {
            addReview(newReview);
            setReviews((prevReviews) => {
                const updatedReviews = [...prevReviews, newReview]
                    .sort((a, b) => parseDate(b.date) - parseDate(a.date))
                    .slice(0, 8);
                return updatedReviews;
            });
            setNewReview({
                name: '',
                rating: 0,
                text: '',
                date: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
            });
        }
    };

    return (
        <div>
            <section id="reviews" className="reviews-section">
                <h2>Patient Reviews</h2>
                <div className="reviews-container">
                    {reviews.map((review, index) => (
                        <div key={index} className="review-card">
                            <FaQuoteLeft className="quote-icon" />
                            <p className="review-text">
                                {review.text.length > 150 ? `${review.text.slice(0, 150)}...` : review.text}
                            </p>
                            <FaQuoteRight className="quote-icon" />
                            <div className="review-details">
                                <h3>{review.name}</h3>
                                <div className="review-rating">
                                    {getStarRating(review.rating)}
                                    <span className="review-date">{formatDate(review.date)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Reviews;
